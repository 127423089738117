.MenuItemHeight{
    height:6rem
}

.MenuItemTitle{
    text-align:right; 
    color:black;
    font-weight: bold;
}

.MenuItemDesc{
    font-size:12;
    text-align:right;
    color:black;
}

.MenuItemCircleActive{
    background-color: #54B4D3;
    width:4rem;
    height:4rem;
    border-radius:2rem
}

.MenuItemCircleInactive{
    background-color: #aeb0b3;
    width:4rem;
    height:4rem;
    border-radius:2rem
}

.MenuItemIconInactive{
    font-size:1rem;
    color: #686a6b;
}

.MenuItemIconActive{
    font-size:1rem;
    color: #0e7da1;
}



.MenuItemCircleActiveMini{
    background-color: #54B4D3;
    width:2rem;
    height:2rem;
    border-radius:1rem
}

.MenuItemCircleInactiveMini{
    background-color: #aeb0b3;
    width:2rem;
    height:2rem;
    border-radius:1rem
}

.MenuItemTitleMini{
    text-align:center; 
    font-size: 0.7rem;
    color:black;
    font-weight: bold;
}