.Subtitle{
    font-size: 0.7rem;
    color: #333333;
}

.Icon{
    color:#54B4D3;
}

.clickable{
    cursor: pointer;
}