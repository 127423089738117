body{
    padding: 0 !important;
}

.customBorder {
    border:1px solid #dee2e6;
    border-left: 10px solid !important;
    border-left-color: var(--color-var) !important;
    border-radius: 0.6rem;
}

.ncTitle {
    font-size: 20px;
    font-weight: bold;
}

.cardBg{
    background-color: white;
}
.badgeMax{
    height: 28px;
}

.floating-bar {
    position: fixed; /* Fixa o botão na tela */
    width: 100%;
    height: 5rem;
    bottom: 0px; /* Distância do rodapé */
    background-color: white; /* Cor de fundo */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Sombra */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Garante que fique sobre outros elementos */
    border: none; /* Remove bordas */
  }